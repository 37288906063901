<template>
  <div class='aadhar-e-sign-main-wrapper'>
    <a-row style='display: unset'>
      <a-col>
        <div style='margin-top: 100px'>
          <div>
            <div class='aadhar-e-sign-title'>Aadhaar eSign</div>
            <div class='aadhar-e-sign-stepper'>Step 9</div>

            <div class='aadhar-e-sign-sub-title-description'>
              Aadhaar esign digitally signs your document needed for KYC and
              account opening
            </div>
            <a-button :disabled="disable" class='button-with-arrow-wrapper' @click='eSign'>
              <div style='text-align: initial'>
                <div class='btn-arrow-text'>Aadhaar eSign</div>
                <div class='btn-arrow-sub-text'>
                  Your aadhar card must be linked to your mobile number
                </div>
              </div>
              <div><arrow-right-outlined style='font-size: 24px' /></div>
            </a-button>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { onMounted, ref } from 'vue';
import { ArrowRightOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import { ESIGN_LOGO, APP_ENV } from '../../../constants';
import services from '../../../services/apis';
import Operations from '../../../utils/operations';

export default {
  components: {
    ArrowRightOutlined,
  },
  setup() {
    const store = useStore();
    const disable = ref(false);
    onMounted(() => {
      window.scrollTo(0, 0);
      services
        .getStages()
        .then((response) => {
          store.dispatch(
            'onboardingStore/updateStage',
            response.data.data.stage,
          );
        })
        .catch(() => {});
      const externalScript = document.createElement('script');
      externalScript.setAttribute(
        'src',
        'https://gateway.digio.in/sdk/v11/digio.js',
      );
      document.head.appendChild(externalScript);
    });
    const options = {
      environment: APP_ENV === 'production' ? 'production' : 'sandbox',
      callback: (response) => {
        console.log(response);
        let payload = {};
        if (Object.prototype.hasOwnProperty.call(response, 'txn_id')) {
          payload = {
            status: 'SUCCESS',
            message: response.message,
          };
        } else if (Object.prototype.hasOwnProperty.call(response, 'error_code')) {
          payload = {
            status: 'FAILED',
            message: response.message,
          };
        }
        services
          .aadhaarEsignStatus(payload)
          .then((aadharStatusResponse) => {
            store.dispatch(
              'onboardingStore/updateStage',
              aadharStatusResponse.data.data.stage,
            );
          })
          .catch(() => {
          });
      },
      is_redirection_approach: false,
      logo: ESIGN_LOGO,
      is_iframe: true,
      redirect_url: '',
    };

    const eSign = () => {
      const message = null;
      const description = 'Please wait while prepare your document for digital signature';
      Operations.notificationInfo(message, description);
      disable.value = true;
      services
        .aadhaarEsign({})
        .then((response) => {
          /**
           * eSignDocumentIds contains a list of document Ids to be signed by a client.
           * A common/default use case is:
           * - 1st document is the account opening document
           * - 2nd document is CVL KRA document (if the client has never done his KRA)
           */
          const { mobile, digioClientId, eSignDocumentIds } = response.data.data;
          const DigioInit = new window.Digio(options);
          DigioInit.init();

          if (eSignDocumentIds) {
            DigioInit.submit(eSignDocumentIds, mobile, digioClientId);
          } else {
            // Fallback to the older method of extracting documentId from eSign response.
            // This implies APIs have stopped supporting multiple documents signing.
            const { documentId } = response.data.data.eSign;
            DigioInit.submit(documentId, mobile, digioClientId);
          }

          disable.value = false;
        })
        .catch((error) => {
          const systemError = Operations.axiosErrorHandler(error);
          const errorMessage = systemError.status;
          const errorDescription = systemError.message
            ? systemError.message
            : '';
          Operations.notificationError(errorMessage, errorDescription);
          disable.value = false;
        });
    };

    return {
      eSign,
      disable,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/aadharESign.scss';
</style>
